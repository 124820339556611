
export default {
  props: {
    target: {
      type: String,
      default: "post",
    },
    lists: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    goTo(item) {
      const { id_community } = item;
      window.open(
        `${process.env.BASE_COMMUNITY}${
          this.target === "listing" ? "mbls" : "mb"
        }/community/${id_community}/${this.target}`,
        "_self"
      );
    },
    loadMore() {
      this.$emit("more");
    },
  },
};
