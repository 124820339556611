
export default {
  name: 'Pinned',
  components: {
    questionAvatar: () => import('@/components/ask-question/questionAvatar.vue'),
    photoGallery: () => import('@modoc-component/src.components.photo-gallery/photo-gallery.vue')
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    member: {
      type: Object,
      default: () => {}
    }
  }
}
