
export default {
  props: {
    lists: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goJoin (item) {
      this.$router.push(this.localeLocation({ path: `/${item.id}`, params: { detail: item } }))
    }
  }
}
